(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/tanstack-query-core/assets/javascripts/query-core-development.js') >= 0) return;  svs.modules.push('/components/libs/tanstack-query-core/assets/javascripts/query-core-development.js');
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
      typeof define === 'function' && define.amd ? define(['exports'], factory) :
          (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.QueryCore = {}));
})(this, (function (exports) { 'use strict';

  class Subscribable {
    constructor() {
      this.listeners = [];
      this.subscribe = this.subscribe.bind(this);
    }

    subscribe(listener) {
      this.listeners.push(listener);
      this.onSubscribe();
      return () => {
        this.listeners = this.listeners.filter(x => x !== listener);
        this.onUnsubscribe();
      };
    }

    hasListeners() {
      return this.listeners.length > 0;
    }

    onSubscribe() {
    }

    onUnsubscribe() {
    }

  }

  const isServer = typeof window === 'undefined';
  function noop() {
    return undefined;
  }
  function functionalUpdate(updater, input) {
    return typeof updater === 'function' ? updater(input) : updater;
  }
  function isValidTimeout(value) {
    return typeof value === 'number' && value >= 0 && value !== Infinity;
  }
  function difference(array1, array2) {
    return array1.filter(x => array2.indexOf(x) === -1);
  }
  function replaceAt(array, index, value) {
    const copy = array.slice(0);
    copy[index] = value;
    return copy;
  }
  function timeUntilStale(updatedAt, staleTime) {
    return Math.max(updatedAt + (staleTime || 0) - Date.now(), 0);
  }
  function parseQueryArgs(arg1, arg2, arg3) {
    if (!isQueryKey(arg1)) {
      return arg1;
    }

    if (typeof arg2 === 'function') {
      return { ...arg3,
        queryKey: arg1,
        queryFn: arg2
      };
    }

    return { ...arg2,
      queryKey: arg1
    };
  }
  function parseMutationArgs(arg1, arg2, arg3) {
    if (isQueryKey(arg1)) {
      if (typeof arg2 === 'function') {
        return { ...arg3,
          mutationKey: arg1,
          mutationFn: arg2
        };
      }

      return { ...arg2,
        mutationKey: arg1
      };
    }

    if (typeof arg1 === 'function') {
      return { ...arg2,
        mutationFn: arg1
      };
    }

    return { ...arg1
    };
  }
  function parseFilterArgs(arg1, arg2, arg3) {
    return isQueryKey(arg1) ? [{ ...arg2,
      queryKey: arg1
    }, arg3] : [arg1 || {}, arg2];
  }
  function parseMutationFilterArgs(arg1, arg2, arg3) {
    return isQueryKey(arg1) ? [{ ...arg2,
      mutationKey: arg1
    }, arg3] : [arg1 || {}, arg2];
  }
  function matchQuery(filters, query) {
    const {
      type = 'all',
      exact,
      fetchStatus,
      predicate,
      queryKey,
      stale
    } = filters;

    if (isQueryKey(queryKey)) {
      if (exact) {
        if (query.queryHash !== hashQueryKeyByOptions(queryKey, query.options)) {
          return false;
        }
      } else if (!partialMatchKey(query.queryKey, queryKey)) {
        return false;
      }
    }

    if (type !== 'all') {
      const isActive = query.isActive();

      if (type === 'active' && !isActive) {
        return false;
      }

      if (type === 'inactive' && isActive) {
        return false;
      }
    }

    if (typeof stale === 'boolean' && query.isStale() !== stale) {
      return false;
    }

    if (typeof fetchStatus !== 'undefined' && fetchStatus !== query.state.fetchStatus) {
      return false;
    }

    if (predicate && !predicate(query)) {
      return false;
    }

    return true;
  }
  function matchMutation(filters, mutation) {
    const {
      exact,
      fetching,
      predicate,
      mutationKey
    } = filters;

    if (isQueryKey(mutationKey)) {
      if (!mutation.options.mutationKey) {
        return false;
      }

      if (exact) {
        if (hashQueryKey(mutation.options.mutationKey) !== hashQueryKey(mutationKey)) {
          return false;
        }
      } else if (!partialMatchKey(mutation.options.mutationKey, mutationKey)) {
        return false;
      }
    }

    if (typeof fetching === 'boolean' && mutation.state.status === 'loading' !== fetching) {
      return false;
    }

    if (predicate && !predicate(mutation)) {
      return false;
    }

    return true;
  }
  function hashQueryKeyByOptions(queryKey, options) {
    const hashFn = (options == null ? void 0 : options.queryKeyHashFn) || hashQueryKey;
    return hashFn(queryKey);
  }

  function hashQueryKey(queryKey) {
    return JSON.stringify(queryKey, (_, val) => isPlainObject(val) ? Object.keys(val).sort().reduce((result, key) => {
      result[key] = val[key];
      return result;
    }, {}) : val);
  }

  function partialMatchKey(a, b) {
    return partialDeepEqual(a, b);
  }

  function partialDeepEqual(a, b) {
    if (a === b) {
      return true;
    }

    if (typeof a !== typeof b) {
      return false;
    }

    if (a && b && typeof a === 'object' && typeof b === 'object') {
      return !Object.keys(b).some(key => !partialDeepEqual(a[key], b[key]));
    }

    return false;
  }

  function replaceEqualDeep(a, b) {
    if (a === b) {
      return a;
    }

    const array = isPlainArray(a) && isPlainArray(b);

    if (array || isPlainObject(a) && isPlainObject(b)) {
      const aSize = array ? a.length : Object.keys(a).length;
      const bItems = array ? b : Object.keys(b);
      const bSize = bItems.length;
      const copy = array ? [] : {};
      let equalItems = 0;

      for (let i = 0; i < bSize; i++) {
        const key = array ? i : bItems[i];
        copy[key] = replaceEqualDeep(a[key], b[key]);

        if (copy[key] === a[key]) {
          equalItems++;
        }
      }

      return aSize === bSize && equalItems === aSize ? a : copy;
    }

    return b;
  }

  function shallowEqualObjects(a, b) {
    if (a && !b || b && !a) {
      return false;
    }

    for (const key in a) {
      if (a[key] !== b[key]) {
        return false;
      }
    }

    return true;
  }
  function isPlainArray(value) {
    return Array.isArray(value) && value.length === Object.keys(value).length;
  } 

  function isPlainObject(o) {
    if (!hasObjectPrototype(o)) {
      return false;
    } 


    const ctor = o.constructor;

    if (typeof ctor === 'undefined') {
      return true;
    } 


    const prot = ctor.prototype;

    if (!hasObjectPrototype(prot)) {
      return false;
    } 


    if (!prot.hasOwnProperty('isPrototypeOf')) {
      return false;
    } 


    return true;
  }

  function hasObjectPrototype(o) {
    return Object.prototype.toString.call(o) === '[object Object]';
  }

  function isQueryKey(value) {
    return Array.isArray(value);
  }
  function isError(value) {
    return value instanceof Error;
  }
  function sleep(timeout) {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  }

  function scheduleMicrotask(callback) {
    sleep(0).then(callback);
  }
  function getAbortController() {
    if (typeof AbortController === 'function') {
      return new AbortController();
    }
  }
  function replaceData(prevData, data, options) {
    if (options.isDataEqual != null && options.isDataEqual(prevData, data)) {
      return prevData;
    } else if (typeof options.structuralSharing === 'function') {
      return options.structuralSharing(prevData, data);
    } else if (options.structuralSharing !== false) {
      return replaceEqualDeep(prevData, data);
    }

    return data;
  }

  class FocusManager extends Subscribable {
    constructor() {
      super();

      this.setup = onFocus => {
        if (!isServer && window.addEventListener) {
          const listener = () => onFocus(); 


          window.addEventListener('visibilitychange', listener, false);
          window.addEventListener('focus', listener, false);
          return () => {
            window.removeEventListener('visibilitychange', listener);
            window.removeEventListener('focus', listener);
          };
        }
      };
    }

    onSubscribe() {
      if (!this.cleanup) {
        this.setEventListener(this.setup);
      }
    }

    onUnsubscribe() {
      if (!this.hasListeners()) {
        var _this$cleanup;

        (_this$cleanup = this.cleanup) == null ? void 0 : _this$cleanup.call(this);
        this.cleanup = undefined;
      }
    }

    setEventListener(setup) {
      var _this$cleanup2;

      this.setup = setup;
      (_this$cleanup2 = this.cleanup) == null ? void 0 : _this$cleanup2.call(this);
      this.cleanup = setup(focused => {
        if (typeof focused === 'boolean') {
          this.setFocused(focused);
        } else {
          this.onFocus();
        }
      });
    }

    setFocused(focused) {
      this.focused = focused;

      if (focused) {
        this.onFocus();
      }
    }

    onFocus() {
      this.listeners.forEach(listener => {
        listener();
      });
    }

    isFocused() {
      if (typeof this.focused === 'boolean') {
        return this.focused;
      } 


      if (typeof document === 'undefined') {
        return true;
      }

      return [undefined, 'visible', 'prerender'].includes(document.visibilityState);
    }

  }
  const focusManager = new FocusManager();

  class OnlineManager extends Subscribable {
    constructor() {
      super();

      this.setup = onOnline => {
        if (!isServer && window.addEventListener) {
          const listener = () => onOnline(); 


          window.addEventListener('online', listener, false);
          window.addEventListener('offline', listener, false);
          return () => {
            window.removeEventListener('online', listener);
            window.removeEventListener('offline', listener);
          };
        }
      };
    }

    onSubscribe() {
      if (!this.cleanup) {
        this.setEventListener(this.setup);
      }
    }

    onUnsubscribe() {
      if (!this.hasListeners()) {
        var _this$cleanup;

        (_this$cleanup = this.cleanup) == null ? void 0 : _this$cleanup.call(this);
        this.cleanup = undefined;
      }
    }

    setEventListener(setup) {
      var _this$cleanup2;

      this.setup = setup;
      (_this$cleanup2 = this.cleanup) == null ? void 0 : _this$cleanup2.call(this);
      this.cleanup = setup(online => {
        if (typeof online === 'boolean') {
          this.setOnline(online);
        } else {
          this.onOnline();
        }
      });
    }

    setOnline(online) {
      this.online = online;

      if (online) {
        this.onOnline();
      }
    }

    onOnline() {
      this.listeners.forEach(listener => {
        listener();
      });
    }

    isOnline() {
      if (typeof this.online === 'boolean') {
        return this.online;
      }

      if (typeof navigator === 'undefined' || typeof navigator.onLine === 'undefined') {
        return true;
      }

      return navigator.onLine;
    }

  }
  const onlineManager = new OnlineManager();

  function defaultRetryDelay(failureCount) {
    return Math.min(1000 * 2 ** failureCount, 30000);
  }

  function canFetch(networkMode) {
    return (networkMode != null ? networkMode : 'online') === 'online' ? onlineManager.isOnline() : true;
  }
  class CancelledError {
    constructor(options) {
      this.revert = options == null ? void 0 : options.revert;
      this.silent = options == null ? void 0 : options.silent;
    }

  }
  function isCancelledError(value) {
    return value instanceof CancelledError;
  }
  function createRetryer(config) {
    let isRetryCancelled = false;
    let failureCount = 0;
    let isResolved = false;
    let continueFn;
    let promiseResolve;
    let promiseReject;
    const promise = new Promise((outerResolve, outerReject) => {
      promiseResolve = outerResolve;
      promiseReject = outerReject;
    });

    const cancel = cancelOptions => {
      if (!isResolved) {
        reject(new CancelledError(cancelOptions));
        config.abort == null ? void 0 : config.abort();
      }
    };

    const cancelRetry = () => {
      isRetryCancelled = true;
    };

    const continueRetry = () => {
      isRetryCancelled = false;
    };

    const shouldPause = () => !focusManager.isFocused() || config.networkMode !== 'always' && !onlineManager.isOnline();

    const resolve = value => {
      if (!isResolved) {
        isResolved = true;
        config.onSuccess == null ? void 0 : config.onSuccess(value);
        continueFn == null ? void 0 : continueFn();
        promiseResolve(value);
      }
    };

    const reject = value => {
      if (!isResolved) {
        isResolved = true;
        config.onError == null ? void 0 : config.onError(value);
        continueFn == null ? void 0 : continueFn();
        promiseReject(value);
      }
    };

    const pause = () => {
      return new Promise(continueResolve => {
        continueFn = value => {
          if (isResolved || !shouldPause()) {
            return continueResolve(value);
          }
        };

        config.onPause == null ? void 0 : config.onPause();
      }).then(() => {
        continueFn = undefined;

        if (!isResolved) {
          config.onContinue == null ? void 0 : config.onContinue();
        }
      });
    }; 


    const run = () => {
      if (isResolved) {
        return;
      }

      let promiseOrValue; 

      try {
        promiseOrValue = config.fn();
      } catch (error) {
        promiseOrValue = Promise.reject(error);
      }

      Promise.resolve(promiseOrValue).then(resolve).catch(error => {
        var _config$retry, _config$retryDelay;

        if (isResolved) {
          return;
        } 


        const retry = (_config$retry = config.retry) != null ? _config$retry : 3;
        const retryDelay = (_config$retryDelay = config.retryDelay) != null ? _config$retryDelay : defaultRetryDelay;
        const delay = typeof retryDelay === 'function' ? retryDelay(failureCount, error) : retryDelay;
        const shouldRetry = retry === true || typeof retry === 'number' && failureCount < retry || typeof retry === 'function' && retry(failureCount, error);

        if (isRetryCancelled || !shouldRetry) {
          reject(error);
          return;
        }

        failureCount++; 

        config.onFail == null ? void 0 : config.onFail(failureCount, error); 

        sleep(delay) 
            .then(() => {
              if (shouldPause()) {
                return pause();
              }
            }).then(() => {
          if (isRetryCancelled) {
            reject(error);
          } else {
            run();
          }
        });
      });
    }; 


    if (canFetch(config.networkMode)) {
      run();
    } else {
      pause().then(run);
    }

    return {
      promise,
      cancel,
      continue: () => {
        continueFn == null ? void 0 : continueFn();
      },
      cancelRetry,
      continueRetry
    };
  }

  const defaultLogger = console;

  function createNotifyManager() {
    let queue = [];
    let transactions = 0;

    let notifyFn = callback => {
      callback();
    };

    let batchNotifyFn = callback => {
      callback();
    };

    const batch = callback => {
      let result;
      transactions++;

      try {
        result = callback();
      } finally {
        transactions--;

        if (!transactions) {
          flush();
        }
      }

      return result;
    };

    const schedule = callback => {
      if (transactions) {
        queue.push(callback);
      } else {
        scheduleMicrotask(() => {
          notifyFn(callback);
        });
      }
    };


    const batchCalls = callback => {
      return (...args) => {
        schedule(() => {
          callback(...args);
        });
      };
    };

    const flush = () => {
      const originalQueue = queue;
      queue = [];

      if (originalQueue.length) {
        scheduleMicrotask(() => {
          batchNotifyFn(() => {
            originalQueue.forEach(callback => {
              notifyFn(callback);
            });
          });
        });
      }
    };


    const setNotifyFunction = fn => {
      notifyFn = fn;
    };


    const setBatchNotifyFunction = fn => {
      batchNotifyFn = fn;
    };

    return {
      batch,
      batchCalls,
      schedule,
      setNotifyFunction,
      setBatchNotifyFunction
    };
  } 

  const notifyManager = createNotifyManager();

  class Removable {
    destroy() {
      this.clearGcTimeout();
    }

    scheduleGc() {
      this.clearGcTimeout();

      if (isValidTimeout(this.cacheTime)) {
        this.gcTimeout = setTimeout(() => {
          this.optionalRemove();
        }, this.cacheTime);
      }
    }

    updateCacheTime(newCacheTime) {
      this.cacheTime = Math.max(this.cacheTime || 0, newCacheTime != null ? newCacheTime : isServer ? Infinity : 5 * 60 * 1000);
    }

    clearGcTimeout() {
      if (this.gcTimeout) {
        clearTimeout(this.gcTimeout);
        this.gcTimeout = undefined;
      }
    }

  }

  class Query extends Removable {
    constructor(config) {
      super();
      this.abortSignalConsumed = false;
      this.defaultOptions = config.defaultOptions;
      this.setOptions(config.options);
      this.observers = [];
      this.cache = config.cache;
      this.logger = config.logger || defaultLogger;
      this.queryKey = config.queryKey;
      this.queryHash = config.queryHash;
      this.initialState = config.state || getDefaultState$1(this.options);
      this.state = this.initialState;
      this.meta = config.meta;
    }

    setOptions(options) {
      this.options = { ...this.defaultOptions,
        ...options
      };
      this.meta = options == null ? void 0 : options.meta;
      this.updateCacheTime(this.options.cacheTime);
    }

    optionalRemove() {
      if (!this.observers.length && this.state.fetchStatus === 'idle') {
        this.cache.remove(this);
      }
    }

    setData(newData, options) {
      const data = replaceData(this.state.data, newData, this.options); 

      this.dispatch({
        data,
        type: 'success',
        dataUpdatedAt: options == null ? void 0 : options.updatedAt,
        manual: options == null ? void 0 : options.manual
      });
      return data;
    }

    setState(state, setStateOptions) {
      this.dispatch({
        type: 'setState',
        state,
        setStateOptions
      });
    }

    cancel(options) {
      var _this$retryer;

      const promise = this.promise;
      (_this$retryer = this.retryer) == null ? void 0 : _this$retryer.cancel(options);
      return promise ? promise.then(noop).catch(noop) : Promise.resolve();
    }

    destroy() {
      super.destroy();
      this.cancel({
        silent: true
      });
    }

    reset() {
      this.destroy();
      this.setState(this.initialState);
    }

    isActive() {
      return this.observers.some(observer => observer.options.enabled !== false);
    }

    isDisabled() {
      return this.getObserversCount() > 0 && !this.isActive();
    }

    isStale() {
      return this.state.isInvalidated || !this.state.dataUpdatedAt || this.observers.some(observer => observer.getCurrentResult().isStale);
    }

    isStaleByTime(staleTime = 0) {
      return this.state.isInvalidated || !this.state.dataUpdatedAt || !timeUntilStale(this.state.dataUpdatedAt, staleTime);
    }

    onFocus() {
      var _this$retryer2;

      const observer = this.observers.find(x => x.shouldFetchOnWindowFocus());

      if (observer) {
        observer.refetch({
          cancelRefetch: false
        });
      } 


      (_this$retryer2 = this.retryer) == null ? void 0 : _this$retryer2.continue();
    }

    onOnline() {
      var _this$retryer3;

      const observer = this.observers.find(x => x.shouldFetchOnReconnect());

      if (observer) {
        observer.refetch({
          cancelRefetch: false
        });
      } 


      (_this$retryer3 = this.retryer) == null ? void 0 : _this$retryer3.continue();
    }

    addObserver(observer) {
      if (this.observers.indexOf(observer) === -1) {
        this.observers.push(observer); 

        this.clearGcTimeout();
        this.cache.notify({
          type: 'observerAdded',
          query: this,
          observer
        });
      }
    }

    removeObserver(observer) {
      if (this.observers.indexOf(observer) !== -1) {
        this.observers = this.observers.filter(x => x !== observer);

        if (!this.observers.length) {
          if (this.retryer) {
            if (this.abortSignalConsumed) {
              this.retryer.cancel({
                revert: true
              });
            } else {
              this.retryer.cancelRetry();
            }
          }

          this.scheduleGc();
        }

        this.cache.notify({
          type: 'observerRemoved',
          query: this,
          observer
        });
      }
    }

    getObserversCount() {
      return this.observers.length;
    }

    invalidate() {
      if (!this.state.isInvalidated) {
        this.dispatch({
          type: 'invalidate'
        });
      }
    }

    fetch(options, fetchOptions) {
      var _this$options$behavio, _context$fetchOptions;

      if (this.state.fetchStatus !== 'idle') {
        if (this.state.dataUpdatedAt && fetchOptions != null && fetchOptions.cancelRefetch) {
          this.cancel({
            silent: true
          });
        } else if (this.promise) {
          var _this$retryer4;

          (_this$retryer4 = this.retryer) == null ? void 0 : _this$retryer4.continueRetry(); 

          return this.promise;
        }
      } 


      if (options) {
        this.setOptions(options);
      } 


      if (!this.options.queryFn) {
        const observer = this.observers.find(x => x.options.queryFn);

        if (observer) {
          this.setOptions(observer.options);
        }
      }

      if (!Array.isArray(this.options.queryKey)) {
        {
          this.logger.error("As of v4, queryKey needs to be an Array. If you are using a string like 'repoData', please change it to an Array, e.g. ['repoData']");
        }
      }

      const abortController = getAbortController(); 

      const queryFnContext = {
        queryKey: this.queryKey,
        pageParam: undefined,
        meta: this.meta
      }; 

      const addSignalProperty = object => {
        Object.defineProperty(object, 'signal', {
          enumerable: true,
          get: () => {
            if (abortController) {
              this.abortSignalConsumed = true;
              return abortController.signal;
            }

            return undefined;
          }
        });
      };

      addSignalProperty(queryFnContext); 

      const fetchFn = () => {
        if (!this.options.queryFn) {
          return Promise.reject('Missing queryFn');
        }

        this.abortSignalConsumed = false;
        return this.options.queryFn(queryFnContext);
      }; 


      const context = {
        fetchOptions,
        options: this.options,
        queryKey: this.queryKey,
        state: this.state,
        fetchFn,
        meta: this.meta
      };
      addSignalProperty(context);
      (_this$options$behavio = this.options.behavior) == null ? void 0 : _this$options$behavio.onFetch(context); 

      this.revertState = this.state; 

      if (this.state.fetchStatus === 'idle' || this.state.fetchMeta !== ((_context$fetchOptions = context.fetchOptions) == null ? void 0 : _context$fetchOptions.meta)) {
        var _context$fetchOptions2;

        this.dispatch({
          type: 'fetch',
          meta: (_context$fetchOptions2 = context.fetchOptions) == null ? void 0 : _context$fetchOptions2.meta
        });
      }

      const onError = error => {
        if (!(isCancelledError(error) && error.silent)) {
          this.dispatch({
            type: 'error',
            error: error
          });
        }

        if (!isCancelledError(error)) {
          var _this$cache$config$on, _this$cache$config;

          (_this$cache$config$on = (_this$cache$config = this.cache.config).onError) == null ? void 0 : _this$cache$config$on.call(_this$cache$config, error, this);

          {
            this.logger.error(error);
          }
        }

        if (!this.isFetchingOptimistic) {
          this.scheduleGc();
        }

        this.isFetchingOptimistic = false;
      }; 


      this.retryer = createRetryer({
        fn: context.fetchFn,
        abort: abortController == null ? void 0 : abortController.abort.bind(abortController),
        onSuccess: data => {
          var _this$cache$config$on2, _this$cache$config2;

          if (typeof data === 'undefined') {
            {
              this.logger.error("Query data cannot be undefined. Please make sure to return a value other than undefined from your query function. Affected query key: " + this.queryHash);
            }

            onError(new Error('undefined'));
            return;
          }

          this.setData(data); 

          (_this$cache$config$on2 = (_this$cache$config2 = this.cache.config).onSuccess) == null ? void 0 : _this$cache$config$on2.call(_this$cache$config2, data, this);

          if (!this.isFetchingOptimistic) {
            this.scheduleGc();
          }

          this.isFetchingOptimistic = false;
        },
        onError,
        onFail: (failureCount, error) => {
          this.dispatch({
            type: 'failed',
            failureCount,
            error
          });
        },
        onPause: () => {
          this.dispatch({
            type: 'pause'
          });
        },
        onContinue: () => {
          this.dispatch({
            type: 'continue'
          });
        },
        retry: context.options.retry,
        retryDelay: context.options.retryDelay,
        networkMode: context.options.networkMode
      });
      this.promise = this.retryer.promise;
      return this.promise;
    }

    dispatch(action) {
      const reducer = state => {
        var _action$meta, _action$dataUpdatedAt;

        switch (action.type) {
          case 'failed':
            return { ...state,
              fetchFailureCount: action.failureCount,
              fetchFailureReason: action.error
            };

          case 'pause':
            return { ...state,
              fetchStatus: 'paused'
            };

          case 'continue':
            return { ...state,
              fetchStatus: 'fetching'
            };

          case 'fetch':
            return { ...state,
              fetchFailureCount: 0,
              fetchFailureReason: null,
              fetchMeta: (_action$meta = action.meta) != null ? _action$meta : null,
              fetchStatus: canFetch(this.options.networkMode) ? 'fetching' : 'paused',
              ...(!state.dataUpdatedAt && {
                error: null,
                status: 'loading'
              })
            };

          case 'success':
            return { ...state,
              data: action.data,
              dataUpdateCount: state.dataUpdateCount + 1,
              dataUpdatedAt: (_action$dataUpdatedAt = action.dataUpdatedAt) != null ? _action$dataUpdatedAt : Date.now(),
              error: null,
              isInvalidated: false,
              status: 'success',
              ...(!action.manual && {
                fetchStatus: 'idle',
                fetchFailureCount: 0,
                fetchFailureReason: null
              })
            };

          case 'error':
            const error = action.error;

            if (isCancelledError(error) && error.revert && this.revertState) {
              return { ...this.revertState
              };
            }

            return { ...state,
              error: error,
              errorUpdateCount: state.errorUpdateCount + 1,
              errorUpdatedAt: Date.now(),
              fetchFailureCount: state.fetchFailureCount + 1,
              fetchFailureReason: error,
              fetchStatus: 'idle',
              status: 'error'
            };

          case 'invalidate':
            return { ...state,
              isInvalidated: true
            };

          case 'setState':
            return { ...state,
              ...action.state
            };
        }
      };

      this.state = reducer(this.state);
      notifyManager.batch(() => {
        this.observers.forEach(observer => {
          observer.onQueryUpdate(action);
        });
        this.cache.notify({
          query: this,
          type: 'updated',
          action
        });
      });
    }

  }

  function getDefaultState$1(options) {
    const data = typeof options.initialData === 'function' ? options.initialData() : options.initialData;
    const hasInitialData = typeof options.initialData !== 'undefined';
    const initialDataUpdatedAt = hasInitialData ? typeof options.initialDataUpdatedAt === 'function' ? options.initialDataUpdatedAt() : options.initialDataUpdatedAt : 0;
    const hasData = typeof data !== 'undefined';
    return {
      data,
      dataUpdateCount: 0,
      dataUpdatedAt: hasData ? initialDataUpdatedAt != null ? initialDataUpdatedAt : Date.now() : 0,
      error: null,
      errorUpdateCount: 0,
      errorUpdatedAt: 0,
      fetchFailureCount: 0,
      fetchFailureReason: null,
      fetchMeta: null,
      isInvalidated: false,
      status: hasData ? 'success' : 'loading',
      fetchStatus: 'idle'
    };
  }

  class QueryCache extends Subscribable {
    constructor(config) {
      super();
      this.config = config || {};
      this.queries = [];
      this.queriesMap = {};
    }

    build(client, options, state) {
      var _options$queryHash;

      const queryKey = options.queryKey;
      const queryHash = (_options$queryHash = options.queryHash) != null ? _options$queryHash : hashQueryKeyByOptions(queryKey, options);
      let query = this.get(queryHash);

      if (!query) {
        query = new Query({
          cache: this,
          logger: client.getLogger(),
          queryKey,
          queryHash,
          options: client.defaultQueryOptions(options),
          state,
          defaultOptions: client.getQueryDefaults(queryKey),
          meta: options.meta
        });
        this.add(query);
      }

      return query;
    }

    add(query) {
      if (!this.queriesMap[query.queryHash]) {
        this.queriesMap[query.queryHash] = query;
        this.queries.push(query);
        this.notify({
          type: 'added',
          query
        });
      }
    }

    remove(query) {
      const queryInMap = this.queriesMap[query.queryHash];

      if (queryInMap) {
        query.destroy();
        this.queries = this.queries.filter(x => x !== query);

        if (queryInMap === query) {
          delete this.queriesMap[query.queryHash];
        }

        this.notify({
          type: 'removed',
          query
        });
      }
    }

    clear() {
      notifyManager.batch(() => {
        this.queries.forEach(query => {
          this.remove(query);
        });
      });
    }

    get(queryHash) {
      return this.queriesMap[queryHash];
    }

    getAll() {
      return this.queries;
    }

    find(arg1, arg2) {
      const [filters] = parseFilterArgs(arg1, arg2);

      if (typeof filters.exact === 'undefined') {
        filters.exact = true;
      }

      return this.queries.find(query => matchQuery(filters, query));
    }

    findAll(arg1, arg2) {
      const [filters] = parseFilterArgs(arg1, arg2);
      return Object.keys(filters).length > 0 ? this.queries.filter(query => matchQuery(filters, query)) : this.queries;
    }

    notify(event) {
      notifyManager.batch(() => {
        this.listeners.forEach(listener => {
          listener(event);
        });
      });
    }

    onFocus() {
      notifyManager.batch(() => {
        this.queries.forEach(query => {
          query.onFocus();
        });
      });
    }

    onOnline() {
      notifyManager.batch(() => {
        this.queries.forEach(query => {
          query.onOnline();
        });
      });
    }

  }

  class Mutation extends Removable {
    constructor(config) {
      super();
      this.options = { ...config.defaultOptions,
        ...config.options
      };
      this.mutationId = config.mutationId;
      this.mutationCache = config.mutationCache;
      this.logger = config.logger || defaultLogger;
      this.observers = [];
      this.state = config.state || getDefaultState();
      this.meta = config.meta;
      this.updateCacheTime(this.options.cacheTime);
      this.scheduleGc();
    }

    setState(state) {
      this.dispatch({
        type: 'setState',
        state
      });
    }

    addObserver(observer) {
      if (this.observers.indexOf(observer) === -1) {
        this.observers.push(observer); 

        this.clearGcTimeout();
        this.mutationCache.notify({
          type: 'observerAdded',
          mutation: this,
          observer
        });
      }
    }

    removeObserver(observer) {
      this.observers = this.observers.filter(x => x !== observer);
      this.scheduleGc();
      this.mutationCache.notify({
        type: 'observerRemoved',
        mutation: this,
        observer
      });
    }

    optionalRemove() {
      if (!this.observers.length) {
        if (this.state.status === 'loading') {
          this.scheduleGc();
        } else {
          this.mutationCache.remove(this);
        }
      }
    }

    continue() {
      if (this.retryer) {
        this.retryer.continue();
        return this.retryer.promise;
      }

      return this.execute();
    }

    async execute() {
      const executeMutation = () => {
        var _this$options$retry;

        this.retryer = createRetryer({
          fn: () => {
            if (!this.options.mutationFn) {
              return Promise.reject('No mutationFn found');
            }

            return this.options.mutationFn(this.state.variables);
          },
          onFail: (failureCount, error) => {
            this.dispatch({
              type: 'failed',
              failureCount,
              error
            });
          },
          onPause: () => {
            this.dispatch({
              type: 'pause'
            });
          },
          onContinue: () => {
            this.dispatch({
              type: 'continue'
            });
          },
          retry: (_this$options$retry = this.options.retry) != null ? _this$options$retry : 0,
          retryDelay: this.options.retryDelay,
          networkMode: this.options.networkMode
        });
        return this.retryer.promise;
      };

      const restored = this.state.status === 'loading';

      try {
        var _this$mutationCache$c3, _this$mutationCache$c4, _this$options$onSucce, _this$options2, _this$options$onSettl, _this$options3;

        if (!restored) {
          var _this$mutationCache$c, _this$mutationCache$c2, _this$options$onMutat, _this$options;

          this.dispatch({
            type: 'loading',
            variables: this.options.variables
          }); 

          await ((_this$mutationCache$c = (_this$mutationCache$c2 = this.mutationCache.config).onMutate) == null ? void 0 : _this$mutationCache$c.call(_this$mutationCache$c2, this.state.variables, this));
          const context = await ((_this$options$onMutat = (_this$options = this.options).onMutate) == null ? void 0 : _this$options$onMutat.call(_this$options, this.state.variables));

          if (context !== this.state.context) {
            this.dispatch({
              type: 'loading',
              context,
              variables: this.state.variables
            });
          }
        }

        const data = await executeMutation(); 

        await ((_this$mutationCache$c3 = (_this$mutationCache$c4 = this.mutationCache.config).onSuccess) == null ? void 0 : _this$mutationCache$c3.call(_this$mutationCache$c4, data, this.state.variables, this.state.context, this));
        await ((_this$options$onSucce = (_this$options2 = this.options).onSuccess) == null ? void 0 : _this$options$onSucce.call(_this$options2, data, this.state.variables, this.state.context));
        await ((_this$options$onSettl = (_this$options3 = this.options).onSettled) == null ? void 0 : _this$options$onSettl.call(_this$options3, data, null, this.state.variables, this.state.context));
        this.dispatch({
          type: 'success',
          data
        });
        return data;
      } catch (error) {
        try {
          var _this$mutationCache$c5, _this$mutationCache$c6, _this$options$onError, _this$options4, _this$options$onSettl2, _this$options5;

          await ((_this$mutationCache$c5 = (_this$mutationCache$c6 = this.mutationCache.config).onError) == null ? void 0 : _this$mutationCache$c5.call(_this$mutationCache$c6, error, this.state.variables, this.state.context, this));

          if ("development" !== 'production') {
            this.logger.error(error);
          }

          await ((_this$options$onError = (_this$options4 = this.options).onError) == null ? void 0 : _this$options$onError.call(_this$options4, error, this.state.variables, this.state.context));
          await ((_this$options$onSettl2 = (_this$options5 = this.options).onSettled) == null ? void 0 : _this$options$onSettl2.call(_this$options5, undefined, error, this.state.variables, this.state.context));
          throw error;
        } finally {
          this.dispatch({
            type: 'error',
            error: error
          });
        }
      }
    }

    dispatch(action) {
      const reducer = state => {
        switch (action.type) {
          case 'failed':
            return { ...state,
              failureCount: action.failureCount,
              failureReason: action.error
            };

          case 'pause':
            return { ...state,
              isPaused: true
            };

          case 'continue':
            return { ...state,
              isPaused: false
            };

          case 'loading':
            return { ...state,
              context: action.context,
              data: undefined,
              failureCount: 0,
              failureReason: null,
              error: null,
              isPaused: !canFetch(this.options.networkMode),
              status: 'loading',
              variables: action.variables
            };

          case 'success':
            return { ...state,
              data: action.data,
              failureCount: 0,
              failureReason: null,
              error: null,
              status: 'success',
              isPaused: false
            };

          case 'error':
            return { ...state,
              data: undefined,
              error: action.error,
              failureCount: state.failureCount + 1,
              failureReason: action.error,
              isPaused: false,
              status: 'error'
            };

          case 'setState':
            return { ...state,
              ...action.state
            };
        }
      };

      this.state = reducer(this.state);
      notifyManager.batch(() => {
        this.observers.forEach(observer => {
          observer.onMutationUpdate(action);
        });
        this.mutationCache.notify({
          mutation: this,
          type: 'updated',
          action
        });
      });
    }

  }
  function getDefaultState() {
    return {
      context: undefined,
      data: undefined,
      error: null,
      failureCount: 0,
      failureReason: null,
      isPaused: false,
      status: 'idle',
      variables: undefined
    };
  }

  class MutationCache extends Subscribable {
    constructor(config) {
      super();
      this.config = config || {};
      this.mutations = [];
      this.mutationId = 0;
    }

    build(client, options, state) {
      const mutation = new Mutation({
        mutationCache: this,
        logger: client.getLogger(),
        mutationId: ++this.mutationId,
        options: client.defaultMutationOptions(options),
        state,
        defaultOptions: options.mutationKey ? client.getMutationDefaults(options.mutationKey) : undefined,
        meta: options.meta
      });
      this.add(mutation);
      return mutation;
    }

    add(mutation) {
      this.mutations.push(mutation);
      this.notify({
        type: 'added',
        mutation
      });
    }

    remove(mutation) {
      this.mutations = this.mutations.filter(x => x !== mutation);
      this.notify({
        type: 'removed',
        mutation
      });
    }

    clear() {
      notifyManager.batch(() => {
        this.mutations.forEach(mutation => {
          this.remove(mutation);
        });
      });
    }

    getAll() {
      return this.mutations;
    }

    find(filters) {
      if (typeof filters.exact === 'undefined') {
        filters.exact = true;
      }

      return this.mutations.find(mutation => matchMutation(filters, mutation));
    }

    findAll(filters) {
      return this.mutations.filter(mutation => matchMutation(filters, mutation));
    }

    notify(event) {
      notifyManager.batch(() => {
        this.listeners.forEach(listener => {
          listener(event);
        });
      });
    }

    resumePausedMutations() {
      const pausedMutations = this.mutations.filter(x => x.state.isPaused);
      return notifyManager.batch(() => pausedMutations.reduce((promise, mutation) => promise.then(() => mutation.continue().catch(noop)), Promise.resolve()));
    }

  }

  function infiniteQueryBehavior() {
    return {
      onFetch: context => {
        context.fetchFn = () => {
          var _context$fetchOptions, _context$fetchOptions2, _context$fetchOptions3, _context$fetchOptions4, _context$state$data, _context$state$data2;

          const refetchPage = (_context$fetchOptions = context.fetchOptions) == null ? void 0 : (_context$fetchOptions2 = _context$fetchOptions.meta) == null ? void 0 : _context$fetchOptions2.refetchPage;
          const fetchMore = (_context$fetchOptions3 = context.fetchOptions) == null ? void 0 : (_context$fetchOptions4 = _context$fetchOptions3.meta) == null ? void 0 : _context$fetchOptions4.fetchMore;
          const pageParam = fetchMore == null ? void 0 : fetchMore.pageParam;
          const isFetchingNextPage = (fetchMore == null ? void 0 : fetchMore.direction) === 'forward';
          const isFetchingPreviousPage = (fetchMore == null ? void 0 : fetchMore.direction) === 'backward';
          const oldPages = ((_context$state$data = context.state.data) == null ? void 0 : _context$state$data.pages) || [];
          const oldPageParams = ((_context$state$data2 = context.state.data) == null ? void 0 : _context$state$data2.pageParams) || [];
          let newPageParams = oldPageParams;
          let cancelled = false;

          const addSignalProperty = object => {
            Object.defineProperty(object, 'signal', {
              enumerable: true,
              get: () => {
                var _context$signal;

                if ((_context$signal = context.signal) != null && _context$signal.aborted) {
                  cancelled = true;
                } else {
                  var _context$signal2;

                  (_context$signal2 = context.signal) == null ? void 0 : _context$signal2.addEventListener('abort', () => {
                    cancelled = true;
                  });
                }

                return context.signal;
              }
            });
          }; 


          const queryFn = context.options.queryFn || (() => Promise.reject('Missing queryFn'));

          const buildNewPages = (pages, param, page, previous) => {
            newPageParams = previous ? [param, ...newPageParams] : [...newPageParams, param];
            return previous ? [page, ...pages] : [...pages, page];
          }; 


          const fetchPage = (pages, manual, param, previous) => {
            if (cancelled) {
              return Promise.reject('Cancelled');
            }

            if (typeof param === 'undefined' && !manual && pages.length) {
              return Promise.resolve(pages);
            }

            const queryFnContext = {
              queryKey: context.queryKey,
              pageParam: param,
              meta: context.meta
            };
            addSignalProperty(queryFnContext);
            const queryFnResult = queryFn(queryFnContext);
            const promise = Promise.resolve(queryFnResult).then(page => buildNewPages(pages, param, page, previous));
            return promise;
          };

          let promise; 

          if (!oldPages.length) {
            promise = fetchPage([]);
          } 
          else if (isFetchingNextPage) {
            const manual = typeof pageParam !== 'undefined';
            const param = manual ? pageParam : getNextPageParam(context.options, oldPages);
            promise = fetchPage(oldPages, manual, param);
          } 
          else if (isFetchingPreviousPage) {
            const manual = typeof pageParam !== 'undefined';
            const param = manual ? pageParam : getPreviousPageParam(context.options, oldPages);
            promise = fetchPage(oldPages, manual, param, true);
          } 
          else {
            newPageParams = [];
            const manual = typeof context.options.getNextPageParam === 'undefined';
            const shouldFetchFirstPage = refetchPage && oldPages[0] ? refetchPage(oldPages[0], 0, oldPages) : true; 

            promise = shouldFetchFirstPage ? fetchPage([], manual, oldPageParams[0]) : Promise.resolve(buildNewPages([], oldPageParams[0], oldPages[0])); 

            for (let i = 1; i < oldPages.length; i++) {
              promise = promise.then(pages => {
                const shouldFetchNextPage = refetchPage && oldPages[i] ? refetchPage(oldPages[i], i, oldPages) : true;

                if (shouldFetchNextPage) {
                  const param = manual ? oldPageParams[i] : getNextPageParam(context.options, pages);
                  return fetchPage(pages, manual, param);
                }

                return Promise.resolve(buildNewPages(pages, oldPageParams[i], oldPages[i]));
              });
            }
          }

          const finalPromise = promise.then(pages => ({
            pages,
            pageParams: newPageParams
          }));
          return finalPromise;
        };
      }
    };
  }
  function getNextPageParam(options, pages) {
    return options.getNextPageParam == null ? void 0 : options.getNextPageParam(pages[pages.length - 1], pages);
  }
  function getPreviousPageParam(options, pages) {
    return options.getPreviousPageParam == null ? void 0 : options.getPreviousPageParam(pages[0], pages);
  }

  function hasNextPage(options, pages) {
    if (options.getNextPageParam && Array.isArray(pages)) {
      const nextPageParam = getNextPageParam(options, pages);
      return typeof nextPageParam !== 'undefined' && nextPageParam !== null && nextPageParam !== false;
    }
  }

  function hasPreviousPage(options, pages) {
    if (options.getPreviousPageParam && Array.isArray(pages)) {
      const previousPageParam = getPreviousPageParam(options, pages);
      return typeof previousPageParam !== 'undefined' && previousPageParam !== null && previousPageParam !== false;
    }
  }

  class QueryClient {
    constructor(config = {}) {
      this.queryCache = config.queryCache || new QueryCache();
      this.mutationCache = config.mutationCache || new MutationCache();
      this.logger = config.logger || defaultLogger;
      this.defaultOptions = config.defaultOptions || {};
      this.queryDefaults = [];
      this.mutationDefaults = [];
    }

    mount() {
      this.unsubscribeFocus = focusManager.subscribe(() => {
        if (focusManager.isFocused()) {
          this.resumePausedMutations();
          this.queryCache.onFocus();
        }
      });
      this.unsubscribeOnline = onlineManager.subscribe(() => {
        if (onlineManager.isOnline()) {
          this.resumePausedMutations();
          this.queryCache.onOnline();
        }
      });
    }

    unmount() {
      var _this$unsubscribeFocu, _this$unsubscribeOnli;

      (_this$unsubscribeFocu = this.unsubscribeFocus) == null ? void 0 : _this$unsubscribeFocu.call(this);
      (_this$unsubscribeOnli = this.unsubscribeOnline) == null ? void 0 : _this$unsubscribeOnli.call(this);
    }

    isFetching(arg1, arg2) {
      const [filters] = parseFilterArgs(arg1, arg2);
      filters.fetchStatus = 'fetching';
      return this.queryCache.findAll(filters).length;
    }

    isMutating(filters) {
      return this.mutationCache.findAll({ ...filters,
        fetching: true
      }).length;
    }

    getQueryData(queryKey, filters) {
      var _this$queryCache$find;

      return (_this$queryCache$find = this.queryCache.find(queryKey, filters)) == null ? void 0 : _this$queryCache$find.state.data;
    }

    getQueriesData(queryKeyOrFilters) {
      return this.getQueryCache().findAll(queryKeyOrFilters).map(({
                                                                    queryKey,
                                                                    state
                                                                  }) => {
        const data = state.data;
        return [queryKey, data];
      });
    }

    setQueryData(queryKey, updater, options) {
      const query = this.queryCache.find(queryKey);
      const prevData = query == null ? void 0 : query.state.data;
      const data = functionalUpdate(updater, prevData);

      if (typeof data === 'undefined') {
        return undefined;
      }

      const parsedOptions = parseQueryArgs(queryKey);
      const defaultedOptions = this.defaultQueryOptions(parsedOptions);
      return this.queryCache.build(this, defaultedOptions).setData(data, { ...options,
        manual: true
      });
    }

    setQueriesData(queryKeyOrFilters, updater, options) {
      return notifyManager.batch(() => this.getQueryCache().findAll(queryKeyOrFilters).map(({
                                                                                              queryKey
                                                                                            }) => [queryKey, this.setQueryData(queryKey, updater, options)]));
    }

    getQueryState(queryKey, filters) {
      var _this$queryCache$find2;

      return (_this$queryCache$find2 = this.queryCache.find(queryKey, filters)) == null ? void 0 : _this$queryCache$find2.state;
    }

    removeQueries(arg1, arg2) {
      const [filters] = parseFilterArgs(arg1, arg2);
      const queryCache = this.queryCache;
      notifyManager.batch(() => {
        queryCache.findAll(filters).forEach(query => {
          queryCache.remove(query);
        });
      });
    }

    resetQueries(arg1, arg2, arg3) {
      const [filters, options] = parseFilterArgs(arg1, arg2, arg3);
      const queryCache = this.queryCache;
      const refetchFilters = {
        type: 'active',
        ...filters
      };
      return notifyManager.batch(() => {
        queryCache.findAll(filters).forEach(query => {
          query.reset();
        });
        return this.refetchQueries(refetchFilters, options);
      });
    }

    cancelQueries(arg1, arg2, arg3) {
      const [filters, cancelOptions = {}] = parseFilterArgs(arg1, arg2, arg3);

      if (typeof cancelOptions.revert === 'undefined') {
        cancelOptions.revert = true;
      }

      const promises = notifyManager.batch(() => this.queryCache.findAll(filters).map(query => query.cancel(cancelOptions)));
      return Promise.all(promises).then(noop).catch(noop);
    }

    invalidateQueries(arg1, arg2, arg3) {
      const [filters, options] = parseFilterArgs(arg1, arg2, arg3);
      return notifyManager.batch(() => {
        var _ref, _filters$refetchType;

        this.queryCache.findAll(filters).forEach(query => {
          query.invalidate();
        });

        if (filters.refetchType === 'none') {
          return Promise.resolve();
        }

        const refetchFilters = { ...filters,
          type: (_ref = (_filters$refetchType = filters.refetchType) != null ? _filters$refetchType : filters.type) != null ? _ref : 'active'
        };
        return this.refetchQueries(refetchFilters, options);
      });
    }

    refetchQueries(arg1, arg2, arg3) {
      const [filters, options] = parseFilterArgs(arg1, arg2, arg3);
      const promises = notifyManager.batch(() => this.queryCache.findAll(filters).filter(query => !query.isDisabled()).map(query => {
        var _options$cancelRefetc;

        return query.fetch(undefined, { ...options,
          cancelRefetch: (_options$cancelRefetc = options == null ? void 0 : options.cancelRefetch) != null ? _options$cancelRefetc : true,
          meta: {
            refetchPage: filters.refetchPage
          }
        });
      }));
      let promise = Promise.all(promises).then(noop);

      if (!(options != null && options.throwOnError)) {
        promise = promise.catch(noop);
      }

      return promise;
    }

    fetchQuery(arg1, arg2, arg3) {
      const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
      const defaultedOptions = this.defaultQueryOptions(parsedOptions); 

      if (typeof defaultedOptions.retry === 'undefined') {
        defaultedOptions.retry = false;
      }

      const query = this.queryCache.build(this, defaultedOptions);
      return query.isStaleByTime(defaultedOptions.staleTime) ? query.fetch(defaultedOptions) : Promise.resolve(query.state.data);
    }

    prefetchQuery(arg1, arg2, arg3) {
      return this.fetchQuery(arg1, arg2, arg3).then(noop).catch(noop);
    }

    fetchInfiniteQuery(arg1, arg2, arg3) {
      const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
      parsedOptions.behavior = infiniteQueryBehavior();
      return this.fetchQuery(parsedOptions);
    }

    prefetchInfiniteQuery(arg1, arg2, arg3) {
      return this.fetchInfiniteQuery(arg1, arg2, arg3).then(noop).catch(noop);
    }

    resumePausedMutations() {
      return this.mutationCache.resumePausedMutations();
    }

    getQueryCache() {
      return this.queryCache;
    }

    getMutationCache() {
      return this.mutationCache;
    }

    getLogger() {
      return this.logger;
    }

    getDefaultOptions() {
      return this.defaultOptions;
    }

    setDefaultOptions(options) {
      this.defaultOptions = options;
    }

    setQueryDefaults(queryKey, options) {
      const result = this.queryDefaults.find(x => hashQueryKey(queryKey) === hashQueryKey(x.queryKey));

      if (result) {
        result.defaultOptions = options;
      } else {
        this.queryDefaults.push({
          queryKey,
          defaultOptions: options
        });
      }
    }

    getQueryDefaults(queryKey) {
      if (!queryKey) {
        return undefined;
      } 


      const firstMatchingDefaults = this.queryDefaults.find(x => partialMatchKey(queryKey, x.queryKey)); 

      {
        const matchingDefaults = this.queryDefaults.filter(x => partialMatchKey(queryKey, x.queryKey)); 

        if (matchingDefaults.length > 1) {
          this.logger.error("[QueryClient] Several query defaults match with key '" + JSON.stringify(queryKey) + "'. The first matching query defaults are used. Please check how query defaults are registered. Order does matter here. cf. https://react-query.tanstack.com/reference/QueryClient#queryclientsetquerydefaults.");
        }
      }

      return firstMatchingDefaults == null ? void 0 : firstMatchingDefaults.defaultOptions;
    }

    setMutationDefaults(mutationKey, options) {
      const result = this.mutationDefaults.find(x => hashQueryKey(mutationKey) === hashQueryKey(x.mutationKey));

      if (result) {
        result.defaultOptions = options;
      } else {
        this.mutationDefaults.push({
          mutationKey,
          defaultOptions: options
        });
      }
    }

    getMutationDefaults(mutationKey) {
      if (!mutationKey) {
        return undefined;
      } 


      const firstMatchingDefaults = this.mutationDefaults.find(x => partialMatchKey(mutationKey, x.mutationKey)); 

      {
        const matchingDefaults = this.mutationDefaults.filter(x => partialMatchKey(mutationKey, x.mutationKey)); 

        if (matchingDefaults.length > 1) {
          this.logger.error("[QueryClient] Several mutation defaults match with key '" + JSON.stringify(mutationKey) + "'. The first matching mutation defaults are used. Please check how mutation defaults are registered. Order does matter here. cf. https://react-query.tanstack.com/reference/QueryClient#queryclientsetmutationdefaults.");
        }
      }

      return firstMatchingDefaults == null ? void 0 : firstMatchingDefaults.defaultOptions;
    }

    defaultQueryOptions(options) {
      if (options != null && options._defaulted) {
        return options;
      }

      const defaultedOptions = { ...this.defaultOptions.queries,
        ...this.getQueryDefaults(options == null ? void 0 : options.queryKey),
        ...options,
        _defaulted: true
      };

      if (!defaultedOptions.queryHash && defaultedOptions.queryKey) {
        defaultedOptions.queryHash = hashQueryKeyByOptions(defaultedOptions.queryKey, defaultedOptions);
      } 


      if (typeof defaultedOptions.refetchOnReconnect === 'undefined') {
        defaultedOptions.refetchOnReconnect = defaultedOptions.networkMode !== 'always';
      }

      if (typeof defaultedOptions.useErrorBoundary === 'undefined') {
        defaultedOptions.useErrorBoundary = !!defaultedOptions.suspense;
      }

      return defaultedOptions;
    }

    defaultMutationOptions(options) {
      if (options != null && options._defaulted) {
        return options;
      }

      return { ...this.defaultOptions.mutations,
        ...this.getMutationDefaults(options == null ? void 0 : options.mutationKey),
        ...options,
        _defaulted: true
      };
    }

    clear() {
      this.queryCache.clear();
      this.mutationCache.clear();
    }

  }

  class QueryObserver extends Subscribable {
    constructor(client, options) {
      super();
      this.client = client;
      this.options = options;
      this.trackedProps = new Set();
      this.selectError = null;
      this.bindMethods();
      this.setOptions(options);
    }

    bindMethods() {
      this.remove = this.remove.bind(this);
      this.refetch = this.refetch.bind(this);
    }

    onSubscribe() {
      if (this.listeners.length === 1) {
        this.currentQuery.addObserver(this);

        if (shouldFetchOnMount(this.currentQuery, this.options)) {
          this.executeFetch();
        }

        this.updateTimers();
      }
    }

    onUnsubscribe() {
      if (!this.listeners.length) {
        this.destroy();
      }
    }

    shouldFetchOnReconnect() {
      return shouldFetchOn(this.currentQuery, this.options, this.options.refetchOnReconnect);
    }

    shouldFetchOnWindowFocus() {
      return shouldFetchOn(this.currentQuery, this.options, this.options.refetchOnWindowFocus);
    }

    destroy() {
      this.listeners = [];
      this.clearStaleTimeout();
      this.clearRefetchInterval();
      this.currentQuery.removeObserver(this);
    }

    setOptions(options, notifyOptions) {
      const prevOptions = this.options;
      const prevQuery = this.currentQuery;
      this.options = this.client.defaultQueryOptions(options);

      if (!shallowEqualObjects(prevOptions, this.options)) {
        this.client.getQueryCache().notify({
          type: 'observerOptionsUpdated',
          query: this.currentQuery,
          observer: this
        });
      }

      if (typeof this.options.enabled !== 'undefined' && typeof this.options.enabled !== 'boolean') {
        throw new Error('Expected enabled to be a boolean');
      } 


      if (!this.options.queryKey) {
        this.options.queryKey = prevOptions.queryKey;
      }

      this.updateQuery();
      const mounted = this.hasListeners(); 

      if (mounted && shouldFetchOptionally(this.currentQuery, prevQuery, this.options, prevOptions)) {
        this.executeFetch();
      } 


      this.updateResult(notifyOptions); 

      if (mounted && (this.currentQuery !== prevQuery || this.options.enabled !== prevOptions.enabled || this.options.staleTime !== prevOptions.staleTime)) {
        this.updateStaleTimeout();
      }

      const nextRefetchInterval = this.computeRefetchInterval(); 

      if (mounted && (this.currentQuery !== prevQuery || this.options.enabled !== prevOptions.enabled || nextRefetchInterval !== this.currentRefetchInterval)) {
        this.updateRefetchInterval(nextRefetchInterval);
      }
    }

    getOptimisticResult(options) {
      const query = this.client.getQueryCache().build(this.client, options);
      return this.createResult(query, options);
    }

    getCurrentResult() {
      return this.currentResult;
    }

    trackResult(result) {
      const trackedResult = {};
      Object.keys(result).forEach(key => {
        Object.defineProperty(trackedResult, key, {
          configurable: false,
          enumerable: true,
          get: () => {
            this.trackedProps.add(key);
            return result[key];
          }
        });
      });
      return trackedResult;
    }

    getCurrentQuery() {
      return this.currentQuery;
    }

    remove() {
      this.client.getQueryCache().remove(this.currentQuery);
    }

    refetch({
              refetchPage,
              ...options
            } = {}) {
      return this.fetch({ ...options,
        meta: {
          refetchPage
        }
      });
    }

    fetchOptimistic(options) {
      const defaultedOptions = this.client.defaultQueryOptions(options);
      const query = this.client.getQueryCache().build(this.client, defaultedOptions);
      query.isFetchingOptimistic = true;
      return query.fetch().then(() => this.createResult(query, defaultedOptions));
    }

    fetch(fetchOptions) {
      var _fetchOptions$cancelR;

      return this.executeFetch({ ...fetchOptions,
        cancelRefetch: (_fetchOptions$cancelR = fetchOptions.cancelRefetch) != null ? _fetchOptions$cancelR : true
      }).then(() => {
        this.updateResult();
        return this.currentResult;
      });
    }

    executeFetch(fetchOptions) {
      this.updateQuery(); 

      let promise = this.currentQuery.fetch(this.options, fetchOptions);

      if (!(fetchOptions != null && fetchOptions.throwOnError)) {
        promise = promise.catch(noop);
      }

      return promise;
    }

    updateStaleTimeout() {
      this.clearStaleTimeout();

      if (isServer || this.currentResult.isStale || !isValidTimeout(this.options.staleTime)) {
        return;
      }

      const time = timeUntilStale(this.currentResult.dataUpdatedAt, this.options.staleTime); 

      const timeout = time + 1;
      this.staleTimeoutId = setTimeout(() => {
        if (!this.currentResult.isStale) {
          this.updateResult();
        }
      }, timeout);
    }

    computeRefetchInterval() {
      var _this$options$refetch;

      return typeof this.options.refetchInterval === 'function' ? this.options.refetchInterval(this.currentResult.data, this.currentQuery) : (_this$options$refetch = this.options.refetchInterval) != null ? _this$options$refetch : false;
    }

    updateRefetchInterval(nextInterval) {
      this.clearRefetchInterval();
      this.currentRefetchInterval = nextInterval;

      if (isServer || this.options.enabled === false || !isValidTimeout(this.currentRefetchInterval) || this.currentRefetchInterval === 0) {
        return;
      }

      this.refetchIntervalId = setInterval(() => {
        if (this.options.refetchIntervalInBackground || focusManager.isFocused()) {
          this.executeFetch();
        }
      }, this.currentRefetchInterval);
    }

    updateTimers() {
      this.updateStaleTimeout();
      this.updateRefetchInterval(this.computeRefetchInterval());
    }

    clearStaleTimeout() {
      if (this.staleTimeoutId) {
        clearTimeout(this.staleTimeoutId);
        this.staleTimeoutId = undefined;
      }
    }

    clearRefetchInterval() {
      if (this.refetchIntervalId) {
        clearInterval(this.refetchIntervalId);
        this.refetchIntervalId = undefined;
      }
    }

    createResult(query, options) {
      const prevQuery = this.currentQuery;
      const prevOptions = this.options;
      const prevResult = this.currentResult;
      const prevResultState = this.currentResultState;
      const prevResultOptions = this.currentResultOptions;
      const queryChange = query !== prevQuery;
      const queryInitialState = queryChange ? query.state : this.currentQueryInitialState;
      const prevQueryResult = queryChange ? this.currentResult : this.previousQueryResult;
      const {
        state
      } = query;
      let {
        dataUpdatedAt,
        error,
        errorUpdatedAt,
        fetchStatus,
        status
      } = state;
      let isPreviousData = false;
      let isPlaceholderData = false;
      let data; 

      if (options._optimisticResults) {
        const mounted = this.hasListeners();
        const fetchOnMount = !mounted && shouldFetchOnMount(query, options);
        const fetchOptionally = mounted && shouldFetchOptionally(query, prevQuery, options, prevOptions);

        if (fetchOnMount || fetchOptionally) {
          fetchStatus = canFetch(query.options.networkMode) ? 'fetching' : 'paused';

          if (!dataUpdatedAt) {
            status = 'loading';
          }
        }

        if (options._optimisticResults === 'isRestoring') {
          fetchStatus = 'idle';
        }
      } 


      if (options.keepPreviousData && !state.dataUpdateCount && prevQueryResult != null && prevQueryResult.isSuccess && status !== 'error') {
        data = prevQueryResult.data;
        dataUpdatedAt = prevQueryResult.dataUpdatedAt;
        status = prevQueryResult.status;
        isPreviousData = true;
      } 
      else if (options.select && typeof state.data !== 'undefined') {
        if (prevResult && state.data === (prevResultState == null ? void 0 : prevResultState.data) && options.select === this.selectFn) {
          data = this.selectResult;
        } else {
          try {
            this.selectFn = options.select;
            data = options.select(state.data);
            data = replaceData(prevResult == null ? void 0 : prevResult.data, data, options);
            this.selectResult = data;
            this.selectError = null;
          } catch (selectError) {
            {
              this.client.getLogger().error(selectError);
            }

            this.selectError = selectError;
          }
        }
      } 
      else {
        data = state.data;
      } 


      if (typeof options.placeholderData !== 'undefined' && typeof data === 'undefined' && status === 'loading') {
        let placeholderData; 

        if (prevResult != null && prevResult.isPlaceholderData && options.placeholderData === (prevResultOptions == null ? void 0 : prevResultOptions.placeholderData)) {
          placeholderData = prevResult.data;
        } else {
          placeholderData = typeof options.placeholderData === 'function' ? options.placeholderData() : options.placeholderData;

          if (options.select && typeof placeholderData !== 'undefined') {
            try {
              placeholderData = options.select(placeholderData);
              placeholderData = replaceData(prevResult == null ? void 0 : prevResult.data, placeholderData, options);
              this.selectError = null;
            } catch (selectError) {
              {
                this.client.getLogger().error(selectError);
              }

              this.selectError = selectError;
            }
          }
        }

        if (typeof placeholderData !== 'undefined') {
          status = 'success';
          data = placeholderData;
          isPlaceholderData = true;
        }
      }

      if (this.selectError) {
        error = this.selectError;
        data = this.selectResult;
        errorUpdatedAt = Date.now();
        status = 'error';
      }

      const isFetching = fetchStatus === 'fetching';
      const isLoading = status === 'loading';
      const isError = status === 'error';
      const result = {
        status,
        fetchStatus,
        isLoading,
        isSuccess: status === 'success',
        isError,
        isInitialLoading: isLoading && isFetching,
        data,
        dataUpdatedAt,
        error,
        errorUpdatedAt,
        failureCount: state.fetchFailureCount,
        failureReason: state.fetchFailureReason,
        errorUpdateCount: state.errorUpdateCount,
        isFetched: state.dataUpdateCount > 0 || state.errorUpdateCount > 0,
        isFetchedAfterMount: state.dataUpdateCount > queryInitialState.dataUpdateCount || state.errorUpdateCount > queryInitialState.errorUpdateCount,
        isFetching,
        isRefetching: isFetching && !isLoading,
        isLoadingError: isError && state.dataUpdatedAt === 0,
        isPaused: fetchStatus === 'paused',
        isPlaceholderData,
        isPreviousData,
        isRefetchError: isError && state.dataUpdatedAt !== 0,
        isStale: isStale(query, options),
        refetch: this.refetch,
        remove: this.remove
      };
      return result;
    }

    updateResult(notifyOptions) {
      const prevResult = this.currentResult;
      const nextResult = this.createResult(this.currentQuery, this.options);
      this.currentResultState = this.currentQuery.state;
      this.currentResultOptions = this.options; 

      if (shallowEqualObjects(nextResult, prevResult)) {
        return;
      }

      this.currentResult = nextResult; 

      const defaultNotifyOptions = {
        cache: true
      };

      const shouldNotifyListeners = () => {
        if (!prevResult) {
          return true;
        }

        const {
          notifyOnChangeProps
        } = this.options;

        if (notifyOnChangeProps === 'all' || !notifyOnChangeProps && !this.trackedProps.size) {
          return true;
        }

        const includedProps = new Set(notifyOnChangeProps != null ? notifyOnChangeProps : this.trackedProps);

        if (this.options.useErrorBoundary) {
          includedProps.add('error');
        }

        return Object.keys(this.currentResult).some(key => {
          const typedKey = key;
          const changed = this.currentResult[typedKey] !== prevResult[typedKey];
          return changed && includedProps.has(typedKey);
        });
      };

      if ((notifyOptions == null ? void 0 : notifyOptions.listeners) !== false && shouldNotifyListeners()) {
        defaultNotifyOptions.listeners = true;
      }

      this.notify({ ...defaultNotifyOptions,
        ...notifyOptions
      });
    }

    updateQuery() {
      const query = this.client.getQueryCache().build(this.client, this.options);

      if (query === this.currentQuery) {
        return;
      }

      const prevQuery = this.currentQuery;
      this.currentQuery = query;
      this.currentQueryInitialState = query.state;
      this.previousQueryResult = this.currentResult;

      if (this.hasListeners()) {
        prevQuery == null ? void 0 : prevQuery.removeObserver(this);
        query.addObserver(this);
      }
    }

    onQueryUpdate(action) {
      const notifyOptions = {};

      if (action.type === 'success') {
        notifyOptions.onSuccess = !action.manual;
      } else if (action.type === 'error' && !isCancelledError(action.error)) {
        notifyOptions.onError = true;
      }

      this.updateResult(notifyOptions);

      if (this.hasListeners()) {
        this.updateTimers();
      }
    }

    notify(notifyOptions) {
      notifyManager.batch(() => {
        if (notifyOptions.onSuccess) {
          var _this$options$onSucce, _this$options, _this$options$onSettl, _this$options2;

          (_this$options$onSucce = (_this$options = this.options).onSuccess) == null ? void 0 : _this$options$onSucce.call(_this$options, this.currentResult.data);
          (_this$options$onSettl = (_this$options2 = this.options).onSettled) == null ? void 0 : _this$options$onSettl.call(_this$options2, this.currentResult.data, null);
        } else if (notifyOptions.onError) {
          var _this$options$onError, _this$options3, _this$options$onSettl2, _this$options4;

          (_this$options$onError = (_this$options3 = this.options).onError) == null ? void 0 : _this$options$onError.call(_this$options3, this.currentResult.error);
          (_this$options$onSettl2 = (_this$options4 = this.options).onSettled) == null ? void 0 : _this$options$onSettl2.call(_this$options4, undefined, this.currentResult.error);
        } 


        if (notifyOptions.listeners) {
          this.listeners.forEach(listener => {
            listener(this.currentResult);
          });
        } 


        if (notifyOptions.cache) {
          this.client.getQueryCache().notify({
            query: this.currentQuery,
            type: 'observerResultsUpdated'
          });
        }
      });
    }

  }

  function shouldLoadOnMount(query, options) {
    return options.enabled !== false && !query.state.dataUpdatedAt && !(query.state.status === 'error' && options.retryOnMount === false);
  }

  function shouldFetchOnMount(query, options) {
    return shouldLoadOnMount(query, options) || query.state.dataUpdatedAt > 0 && shouldFetchOn(query, options, options.refetchOnMount);
  }

  function shouldFetchOn(query, options, field) {
    if (options.enabled !== false) {
      const value = typeof field === 'function' ? field(query) : field;
      return value === 'always' || value !== false && isStale(query, options);
    }

    return false;
  }

  function shouldFetchOptionally(query, prevQuery, options, prevOptions) {
    return options.enabled !== false && (query !== prevQuery || prevOptions.enabled === false) && (!options.suspense || query.state.status !== 'error') && isStale(query, options);
  }

  function isStale(query, options) {
    return query.isStaleByTime(options.staleTime);
  }

  class QueriesObserver extends Subscribable {
    constructor(client, queries) {
      super();
      this.client = client;
      this.queries = [];
      this.result = [];
      this.observers = [];
      this.observersMap = {};

      if (queries) {
        this.setQueries(queries);
      }
    }

    onSubscribe() {
      if (this.listeners.length === 1) {
        this.observers.forEach(observer => {
          observer.subscribe(result => {
            this.onUpdate(observer, result);
          });
        });
      }
    }

    onUnsubscribe() {
      if (!this.listeners.length) {
        this.destroy();
      }
    }

    destroy() {
      this.listeners = [];
      this.observers.forEach(observer => {
        observer.destroy();
      });
    }

    setQueries(queries, notifyOptions) {
      this.queries = queries;
      notifyManager.batch(() => {
        const prevObservers = this.observers;
        const newObserverMatches = this.findMatchingObservers(this.queries); 

        newObserverMatches.forEach(match => match.observer.setOptions(match.defaultedQueryOptions, notifyOptions));
        const newObservers = newObserverMatches.map(match => match.observer);
        const newObserversMap = Object.fromEntries(newObservers.map(observer => [observer.options.queryHash, observer]));
        const newResult = newObservers.map(observer => observer.getCurrentResult());
        const hasIndexChange = newObservers.some((observer, index) => observer !== prevObservers[index]);

        if (prevObservers.length === newObservers.length && !hasIndexChange) {
          return;
        }

        this.observers = newObservers;
        this.observersMap = newObserversMap;
        this.result = newResult;

        if (!this.hasListeners()) {
          return;
        }

        difference(prevObservers, newObservers).forEach(observer => {
          observer.destroy();
        });
        difference(newObservers, prevObservers).forEach(observer => {
          observer.subscribe(result => {
            this.onUpdate(observer, result);
          });
        });
        this.notify();
      });
    }

    getCurrentResult() {
      return this.result;
    }

    getQueries() {
      return this.observers.map(observer => observer.getCurrentQuery());
    }

    getOptimisticResult(queries) {
      return this.findMatchingObservers(queries).map(match => match.observer.getOptimisticResult(match.defaultedQueryOptions));
    }

    findMatchingObservers(queries) {
      const prevObservers = this.observers;
      const defaultedQueryOptions = queries.map(options => this.client.defaultQueryOptions(options));
      const matchingObservers = defaultedQueryOptions.flatMap(defaultedOptions => {
        const match = prevObservers.find(observer => observer.options.queryHash === defaultedOptions.queryHash);

        if (match != null) {
          return [{
            defaultedQueryOptions: defaultedOptions,
            observer: match
          }];
        }

        return [];
      });
      const matchedQueryHashes = matchingObservers.map(match => match.defaultedQueryOptions.queryHash);
      const unmatchedQueries = defaultedQueryOptions.filter(defaultedOptions => !matchedQueryHashes.includes(defaultedOptions.queryHash));
      const unmatchedObservers = prevObservers.filter(prevObserver => !matchingObservers.some(match => match.observer === prevObserver));

      const getObserver = options => {
        const defaultedOptions = this.client.defaultQueryOptions(options);
        const currentObserver = this.observersMap[defaultedOptions.queryHash];
        return currentObserver != null ? currentObserver : new QueryObserver(this.client, defaultedOptions);
      };

      const newOrReusedObservers = unmatchedQueries.map((options, index) => {
        if (options.keepPreviousData) {
          const previouslyUsedObserver = unmatchedObservers[index];

          if (previouslyUsedObserver !== undefined) {
            return {
              defaultedQueryOptions: options,
              observer: previouslyUsedObserver
            };
          }
        }

        return {
          defaultedQueryOptions: options,
          observer: getObserver(options)
        };
      });

      const sortMatchesByOrderOfQueries = (a, b) => defaultedQueryOptions.indexOf(a.defaultedQueryOptions) - defaultedQueryOptions.indexOf(b.defaultedQueryOptions);

      return matchingObservers.concat(newOrReusedObservers).sort(sortMatchesByOrderOfQueries);
    }

    onUpdate(observer, result) {
      const index = this.observers.indexOf(observer);

      if (index !== -1) {
        this.result = replaceAt(this.result, index, result);
        this.notify();
      }
    }

    notify() {
      notifyManager.batch(() => {
        this.listeners.forEach(listener => {
          listener(this.result);
        });
      });
    }

  }

  class InfiniteQueryObserver extends QueryObserver {
    constructor(client, options) {
      super(client, options);
    }

    bindMethods() {
      super.bindMethods();
      this.fetchNextPage = this.fetchNextPage.bind(this);
      this.fetchPreviousPage = this.fetchPreviousPage.bind(this);
    }

    setOptions(options, notifyOptions) {
      super.setOptions({ ...options,
        behavior: infiniteQueryBehavior()
      }, notifyOptions);
    }

    getOptimisticResult(options) {
      options.behavior = infiniteQueryBehavior();
      return super.getOptimisticResult(options);
    }

    fetchNextPage({
                    pageParam,
                    ...options
                  } = {}) {
      return this.fetch({ ...options,
        meta: {
          fetchMore: {
            direction: 'forward',
            pageParam
          }
        }
      });
    }

    fetchPreviousPage({
                        pageParam,
                        ...options
                      } = {}) {
      return this.fetch({ ...options,
        meta: {
          fetchMore: {
            direction: 'backward',
            pageParam
          }
        }
      });
    }

    createResult(query, options) {
      var _state$data, _state$data2, _state$fetchMeta, _state$fetchMeta$fetc, _state$fetchMeta2, _state$fetchMeta2$fet;

      const {
        state
      } = query;
      const result = super.createResult(query, options);
      return { ...result,
        fetchNextPage: this.fetchNextPage,
        fetchPreviousPage: this.fetchPreviousPage,
        hasNextPage: hasNextPage(options, (_state$data = state.data) == null ? void 0 : _state$data.pages),
        hasPreviousPage: hasPreviousPage(options, (_state$data2 = state.data) == null ? void 0 : _state$data2.pages),
        isFetchingNextPage: state.fetchStatus === 'fetching' && ((_state$fetchMeta = state.fetchMeta) == null ? void 0 : (_state$fetchMeta$fetc = _state$fetchMeta.fetchMore) == null ? void 0 : _state$fetchMeta$fetc.direction) === 'forward',
        isFetchingPreviousPage: state.fetchStatus === 'fetching' && ((_state$fetchMeta2 = state.fetchMeta) == null ? void 0 : (_state$fetchMeta2$fet = _state$fetchMeta2.fetchMore) == null ? void 0 : _state$fetchMeta2$fet.direction) === 'backward'
      };
    }

  }

  class MutationObserver extends Subscribable {
    constructor(client, options) {
      super();
      this.client = client;
      this.setOptions(options);
      this.bindMethods();
      this.updateResult();
    }

    bindMethods() {
      this.mutate = this.mutate.bind(this);
      this.reset = this.reset.bind(this);
    }

    setOptions(options) {
      const prevOptions = this.options;
      this.options = this.client.defaultMutationOptions(options);

      if (!shallowEqualObjects(prevOptions, this.options)) {
        this.client.getMutationCache().notify({
          type: 'observerOptionsUpdated',
          mutation: this.currentMutation,
          observer: this
        });
      }
    }

    onUnsubscribe() {
      if (!this.listeners.length) {
        var _this$currentMutation;

        (_this$currentMutation = this.currentMutation) == null ? void 0 : _this$currentMutation.removeObserver(this);
      }
    }

    onMutationUpdate(action) {
      this.updateResult(); 

      const notifyOptions = {
        listeners: true
      };

      if (action.type === 'success') {
        notifyOptions.onSuccess = true;
      } else if (action.type === 'error') {
        notifyOptions.onError = true;
      }

      this.notify(notifyOptions);
    }

    getCurrentResult() {
      return this.currentResult;
    }

    reset() {
      this.currentMutation = undefined;
      this.updateResult();
      this.notify({
        listeners: true
      });
    }

    mutate(variables, options) {
      this.mutateOptions = options;

      if (this.currentMutation) {
        this.currentMutation.removeObserver(this);
      }

      this.currentMutation = this.client.getMutationCache().build(this.client, { ...this.options,
        variables: typeof variables !== 'undefined' ? variables : this.options.variables
      });
      this.currentMutation.addObserver(this);
      return this.currentMutation.execute();
    }

    updateResult() {
      const state = this.currentMutation ? this.currentMutation.state : getDefaultState();
      const result = { ...state,
        isLoading: state.status === 'loading',
        isSuccess: state.status === 'success',
        isError: state.status === 'error',
        isIdle: state.status === 'idle',
        mutate: this.mutate,
        reset: this.reset
      };
      this.currentResult = result;
    }

    notify(options) {
      notifyManager.batch(() => {
        if (this.mutateOptions) {
          if (options.onSuccess) {
            var _this$mutateOptions$o, _this$mutateOptions, _this$mutateOptions$o2, _this$mutateOptions2;

            (_this$mutateOptions$o = (_this$mutateOptions = this.mutateOptions).onSuccess) == null ? void 0 : _this$mutateOptions$o.call(_this$mutateOptions, this.currentResult.data, this.currentResult.variables, this.currentResult.context);
            (_this$mutateOptions$o2 = (_this$mutateOptions2 = this.mutateOptions).onSettled) == null ? void 0 : _this$mutateOptions$o2.call(_this$mutateOptions2, this.currentResult.data, null, this.currentResult.variables, this.currentResult.context);
          } else if (options.onError) {
            var _this$mutateOptions$o3, _this$mutateOptions3, _this$mutateOptions$o4, _this$mutateOptions4;

            (_this$mutateOptions$o3 = (_this$mutateOptions3 = this.mutateOptions).onError) == null ? void 0 : _this$mutateOptions$o3.call(_this$mutateOptions3, this.currentResult.error, this.currentResult.variables, this.currentResult.context);
            (_this$mutateOptions$o4 = (_this$mutateOptions4 = this.mutateOptions).onSettled) == null ? void 0 : _this$mutateOptions$o4.call(_this$mutateOptions4, undefined, this.currentResult.error, this.currentResult.variables, this.currentResult.context);
          }
        } 


        if (options.listeners) {
          this.listeners.forEach(listener => {
            listener(this.currentResult);
          });
        }
      });
    }

  }

  function dehydrateMutation(mutation) {
    return {
      mutationKey: mutation.options.mutationKey,
      state: mutation.state
    };
  } 


  function dehydrateQuery(query) {
    return {
      state: query.state,
      queryKey: query.queryKey,
      queryHash: query.queryHash
    };
  }

  function defaultShouldDehydrateMutation(mutation) {
    return mutation.state.isPaused;
  }

  function defaultShouldDehydrateQuery(query) {
    return query.state.status === 'success';
  }

  function dehydrate(client, options = {}) {
    const mutations = [];
    const queries = [];

    if (options.dehydrateMutations !== false) {
      const shouldDehydrateMutation = options.shouldDehydrateMutation || defaultShouldDehydrateMutation;
      client.getMutationCache().getAll().forEach(mutation => {
        if (shouldDehydrateMutation(mutation)) {
          mutations.push(dehydrateMutation(mutation));
        }
      });
    }

    if (options.dehydrateQueries !== false) {
      const shouldDehydrateQuery = options.shouldDehydrateQuery || defaultShouldDehydrateQuery;
      client.getQueryCache().getAll().forEach(query => {
        if (shouldDehydrateQuery(query)) {
          queries.push(dehydrateQuery(query));
        }
      });
    }

    return {
      mutations,
      queries
    };
  }
  function hydrate(client, dehydratedState, options) {
    if (typeof dehydratedState !== 'object' || dehydratedState === null) {
      return;
    }

    const mutationCache = client.getMutationCache();
    const queryCache = client.getQueryCache(); 

    const mutations = dehydratedState.mutations || []; 

    const queries = dehydratedState.queries || [];
    mutations.forEach(dehydratedMutation => {
      var _options$defaultOptio;

      mutationCache.build(client, { ...(options == null ? void 0 : (_options$defaultOptio = options.defaultOptions) == null ? void 0 : _options$defaultOptio.mutations),
        mutationKey: dehydratedMutation.mutationKey
      }, dehydratedMutation.state);
    });
    queries.forEach(dehydratedQuery => {
      var _options$defaultOptio2;

      const query = queryCache.get(dehydratedQuery.queryHash); 

      if (query) {
        if (query.state.dataUpdatedAt < dehydratedQuery.state.dataUpdatedAt) {
          query.setState(dehydratedQuery.state);
        }

        return;
      } 


      queryCache.build(client, { ...(options == null ? void 0 : (_options$defaultOptio2 = options.defaultOptions) == null ? void 0 : _options$defaultOptio2.queries),
        queryKey: dehydratedQuery.queryKey,
        queryHash: dehydratedQuery.queryHash
      }, dehydratedQuery.state);
    });
  }

  exports.CancelledError = CancelledError;
  exports.InfiniteQueryObserver = InfiniteQueryObserver;
  exports.MutationCache = MutationCache;
  exports.MutationObserver = MutationObserver;
  exports.QueriesObserver = QueriesObserver;
  exports.QueryCache = QueryCache;
  exports.QueryClient = QueryClient;
  exports.QueryObserver = QueryObserver;
  exports.dehydrate = dehydrate;
  exports.focusManager = focusManager;
  exports.hashQueryKey = hashQueryKey;
  exports.hydrate = hydrate;
  exports.isCancelledError = isCancelledError;
  exports.isError = isError;
  exports.isServer = isServer;
  exports.notifyManager = notifyManager;
  exports.onlineManager = onlineManager;
  exports.parseFilterArgs = parseFilterArgs;
  exports.parseMutationArgs = parseMutationArgs;
  exports.parseMutationFilterArgs = parseMutationFilterArgs;
  exports.parseQueryArgs = parseQueryArgs;

  Object.defineProperty(exports, '__esModule', { value: true });

}));


 })(window);